import { Result } from 'antd'
import { JobLevelType } from '../type/Common'

// export const JOB_LEVEL_ENUM: JobLevelType[] = [
//   { id: '0', name: 'Intern', name_vn: 'Thực tập' },
//   { id: '1', name: 'Junior', name_vn: 'Mới tốt nghiệp' },
//   { id: '2', name: 'Senior', name_vn: 'Chuyên viên' },
//   { id: '3', name: 'Supervisor - Leader', name_vn: 'Trưởng nhóm/Giám sát' },
//   { id: '4', name: 'Manager', name_vn: 'Trưởng phòng' },
//   { id: '5', name: 'Director & above', name_vn: 'Giám đốc và vị trí cấp cao' }
// ]

export const JOB_LEVEL_ENUM: JobLevelType[] = [
  { id: 0, name: 'Intern', name_vn: 'Thực tập sinh/ sinh viên' },
  { id: 1, name: 'Junior', name_vn: 'Mới tốt nghiệp/ nhân viên' },
  { id: 2, name: 'Senior', name_vn: 'Chuyên viên' },
  { id: 3, name: 'Supervisor/ leader', name_vn: 'Giám sát/ trưởng nhóm' },
  { id: 4, name: 'Manager', name_vn: 'Quản lý' },
  { id: 5, name: 'Director & above', name_vn: 'Giám đốc & vị trí cấp cao' }
]
export const JOB_LEVEL_ID_MAP = JOB_LEVEL_ENUM.reduce((result, item) => ({ ...result, [item.id]: item }), [])
