import { useData } from '../store'
import { Action, ClientResponse, FastJsonList, JobType, LocationType } from '../../type/Common'
import {
  API_URL_SKILLS,
  API_URL_JOB_CATEGORIES,
  API_URL_GET_JOB_DETAIL,
  API_URL_CONSULTANT_CREATE_JOB_4_CLIENT,
  API_URL_CONSULTANT_GET_JOB_ORDERS,
  API_URL_COMPANY_APPLICANTS_STAGE,
  API_URL_JOBS_CANDIDATES,
  API_URL_JOB_NOTES,
  API_URL_UPDATE_JOB_NOTES,
  API_URL_GET_JOB_CONSULTANTS_DETAIL,
  API_CONSULTANT_MATCH_PROFILE,
  API_CONSULTANT_MATCH_MULTIPLE_PROFILE
} from '../../utilities/APIConstant'
import { AxiosResponse } from 'axios'
import { ENUM_JOB_STATUS, FILTER_SALARY_ENUM } from '../../utilities/Enum'
import { SearchConsultantJobValueType } from '../../components/SearchBar/SearchConsultantList'
import { toPagingAPI } from '../../utilities/common'
import client from '@legacy/@core/utilities/Client'
import { useClient } from '@legacy/@core/hooks/useClient'
import { JOB_LEVEL_ENUM } from '@legacy/@core/utilities/JobLevelEnum'
import Item from 'antd/lib/list/Item'

const JOB_DATA_CHANGE = 'JOB_DATA_CHANGE'
interface JobStatus {
  id: number
  name: string
}
export interface JobLangModal {
  id: number
  name: string
  name_vn: string
}
interface JobLevelItem {
  id: number | string
  name: string
  name_vn?: string
  name_sc?: string
  name_jp?: string
  name_kr?: string
}
interface EmploymentType {
  id: number
  name: string
  name_vn: string
}
export interface Skill<extra = {}> {
  id: number
  type?: string
  attributes: {
    id: number
    name: string
  } & extra
}

export interface JobCategories {
  id: number
  type: string
  attributes: {
    id: number
    name: string
  }
}

export interface JobScreenType {
  skills?: Array<Skill>
  job_categories?: Array<JobCategories>
  job_data?: FastJsonList<JobType, string>
  job_count_status?: any
  job_orders_data?: FastJsonList<JobType, string>
  job_orders_count?: any
  // todo: refactor it

  readyForNext: boolean
  loadingJob: boolean
  loadingJobOrders: boolean
  firstLoadSuccess: boolean
  firstLoadJobOrdersSuccess: boolean
  locationList?: FastJsonList<LocationType>
}

export const JobReducer = (
  state: JobScreenType = {
    readyForNext: false,
    loadingJob: true,
    firstLoadSuccess: false,
    firstLoadJobOrdersSuccess: false,
    loadingJobOrders: false
  },
  action: Action
): JobScreenType => {
  switch (action.type) {
    case JOB_DATA_CHANGE: {
      return { ...state, ...action.payload }
    }
    default:
      return state
  }
}

const getSkill = () =>
  client.get(API_URL_SKILLS, {}).then((response) => {
    return response.data
  })

type JobStageCountType = {
  all_published: number
  all_draft: number
  all_reviewing: number
  all_closed: number
  all_rejected: number
}
/**
 * @deprecated
 * this hook have many thing and will split in next refactor page, create sepecific hook to process your data
 */
export const useJobData = (): {
  fetchListSkills: () => Promise<void>
  fetchListJobCategories: () => Promise<void>
  jobLevel: Array<JobLevelItem>
  jobStatus: Array<JobStatus>
  employmentType: Array<EmploymentType>
  jobLangs: Array<JobLangModal>
  jobProficiencies: Array<JobLangModal>
  createNewJob4Client: (data) => Promise<ClientResponse>
  fetchJobDetailById: (jobId) => Promise<ClientResponse>
  fetchJobDetailById4Client: (jobId) => Promise<ClientResponse>
  fetchListJobOrders: (args: SearchConsultantJobValueType, page: number) => Promise<ClientResponse>
  updateJobById4Client: (jobId, data) => Promise<ClientResponse>
  deleteJob: (jobId: number | string) => Promise<ClientResponse>
  closeJob: (jobId: number | string) => Promise<ClientResponse>
  reopenJob: (jobId: number | string) => Promise<ClientResponse>
  updateApplicantsStage: (candidate_id, job_stage_id, new_index) => Promise<ClientResponse>
  fetchJobApplicant: (jobId) => Promise<ClientResponse>
  createJobNotes: (jobId, data) => Promise<ClientResponse>
  updateJobNotes: (jobId, id, data) => Promise<ClientResponse>
  deleteJobNotes: (jobId, id) => Promise<ClientResponse>
  switchFreelance: (jobId: number, freelance: boolean) => Promise<ClientResponse>
  switchHotJob: (jobId: number, freelance: boolean) => Promise<ClientResponse>
  updateFreelanceBonnus: (jobId: number, data: any) => Promise<ClientResponse>
  updatePriority: (jobId: number, priority: boolean) => Promise<ClientResponse>
  updateMatchingApplicant: (profileID: number, jobId: number) => Promise<ClientResponse>
  updateMatchingMultiApplicant: (profileID: number, params: any) => Promise<ClientResponse>
} => {
  // todo: Refactoring code below for readability
  // separate concern on paging and job fetching
  const { dispatch } = useData()
  const { client } = useClient()
  const jobLangs = [
    { id: 0, name: 'English', name_vn: 'Tiếng Anh' },
    { id: 1, name: 'Vietnamese', name_vn: 'Tiếng Việt' },
    { id: 2, name: 'Japanese', name_vn: 'Tiếng Nhật' },
    { id: 3, name: 'Korean', name_vn: 'Tiếng Hàn' },
    { id: 4, name: 'Chinese (Cantonese)', name_vn: 'Tiếng Quảng Đông (Trung)' },
    { id: 5, name: 'Chinese (Mandarin)', name_vn: 'Tiếng Phổ Thông (Trung)' },
    { id: 6, name: 'Arabic', name_vn: 'Tiếng Ả rập' },
    { id: 7, name: 'Bengali', name_vn: 'Tiếng Bangladesh' },
    { id: 8, name: 'Bulgarian', name_vn: 'Tiếng Bungari' },
    { id: 9, name: 'Burmese', name_vn: 'Tiếng Miến Điện (Myanmar)' },
    { id: 10, name: 'Cambodian', name_vn: 'Tiếng Campuchia' },
    { id: 11, name: 'Cebuano', name_vn: 'Tiếng Bisaya (Philippines)' },
    { id: 12, name: 'Czech', name_vn: 'Tiếng Séc' },
    { id: 13, name: 'Danish', name_vn: 'Tiếng Đan Mạch' },
    { id: 14, name: 'Dutch', name_vn: 'Tiếng Hà Lan' },
    { id: 15, name: 'Finnish', name_vn: 'Tiếng Phần Lan' },
    { id: 16, name: 'French', name_vn: 'Tiếng Pháp' },
    { id: 17, name: 'German', name_vn: 'Tiếng Đức' },
    { id: 18, name: 'Greek', name_vn: 'Tiếng Hy Lạp' },
    { id: 19, name: 'Hindi', name_vn: 'Tiếng Ấn Độ' },
    { id: 20, name: 'Hungarian', name_vn: 'Tiếng Hungari' },
    { id: 21, name: 'Indonesian', name_vn: 'Tiếng Indonesia' },
    { id: 22, name: 'Italian', name_vn: 'Tiếng Ý' },
    { id: 23, name: 'Javanese', name_vn: 'Tiếng Java (Indonesia)' },
    { id: 24, name: 'Laotian', name_vn: 'Tiếng Lào' },
    { id: 25, name: 'Malay', name_vn: 'Tiếng Mã Lai (Malaysia)' },
    { id: 26, name: 'Norwegian', name_vn: 'Tiếng Na Uy' },
    { id: 27, name: 'Polish', name_vn: 'Tiếng Ba Lan' },
    { id: 28, name: 'Portuguese', name_vn: 'Tiếng Bồ Đào Nha' },
    { id: 29, name: 'Romanian', name_vn: 'Tiếng Romanian' },
    { id: 30, name: 'Russian', name_vn: 'Tiếng Nga' },
    { id: 31, name: 'Spanish', name_vn: 'Tiếng Tây Ban Nha' },
    { id: 32, name: 'Swedish', name_vn: 'Tiếng Thụy Điển' },
    { id: 33, name: 'Tagolog', name_vn: 'Tiếng Tagalog (Philippines)' },
    { id: 34, name: 'Taiwanese', name_vn: 'Tiếng Đài Loan' },
    { id: 35, name: 'Thai', name_vn: 'Tiếng Thái' },
    { id: 36, name: 'Turkish', name_vn: 'Tiếng Thổ Nhĩ Kỳ' },
    { id: 37, name: 'Ukranian', name_vn: 'Tiếng Ukraina' }
  ]
  const jobProficiencies = [
    { name: 'Basic', name_vn: 'Cơ bản', id: 0 },
    { name: 'Intermediate', name_vn: 'Trung cấp', id: 1 },
    { name: 'Advanced', name_vn: 'Cao cấp', id: 2 },
    { name: 'Expert', name_vn: 'Chuyên gia', id: 3 }
  ]
  const jobLevel = JOB_LEVEL_ENUM
  const employmentType = [
    { id: 0, name: 'Full time', name_vn: 'Toàn thời gian' },
    { id: 1, name: 'Part time', name_vn: 'Bán thời gian' },
    { id: 2, name: 'Internship', name_vn: 'Thực tập sinh' },
    { id: 5, name: 'Freelancer', name_vn: 'Làm việc tự do' },
    { id: 9, name: 'Remote work', name_vn: 'Làm việc từ xa' },
    { id: 6, name: 'Hybrid work', name_vn: 'Làm việc hybrid' }
  ]

  const jobStatus = [
    { id: 0, name: 'Draft' },
    { id: 1, name: 'Published' },
    { id: 2, name: 'Closed' }
  ]

  const fetchListSkills = () => {
    return getSkill().then((data) => {
      dispatch({ type: JOB_DATA_CHANGE, payload: { skills: data } })
    })
  }

  const fetchListJobCategories = () =>
    client.get(API_URL_JOB_CATEGORIES, {}).then((response) => {
      const job_categories = response.data.data
      dispatch({ type: JOB_DATA_CHANGE, payload: { job_categories } })
    })

  const createNewJob4Client = (data: any) => {
    const { job_languages_attributes, recruiter_ids, ...rest } = data
    const params = {
      ...rest,
      job_languages_attributes: job_languages_attributes.filter((lang) => lang.language !== undefined),
      recruiter_ids: recruiter_ids
    }

    return client.post(API_URL_CONSULTANT_CREATE_JOB_4_CLIENT, { data: params })
  }
  const fetchListJobOrders = (query, page: number) => {
    const { q, freelance, job_order_type, salary_e, ...rest } = query

    dispatch({ type: JOB_DATA_CHANGE, payload: { loadingJobOrders: true, job_orders_data: [] } })
    const user_ids =
      typeof query.user_ids === 'string' || typeof query.user_ids === 'number' ? [query.user_ids] : query.user_ids || undefined
    const salaryFilter = FILTER_SALARY_ENUM.find((item) => item.id === salary_e)
    return client
      .get(
        toPagingAPI(
          API_URL_CONSULTANT_GET_JOB_ORDERS,
          {
            ...rest,
            ...(salaryFilter ? { min_salary: salaryFilter.min_salary, max_salary: salaryFilter.max_salary } : {}),
            title: q,
            freelance: freelance ? freelance : undefined,
            job_order_type: job_order_type ? 1 : undefined,
            user_ids
          },
          page
        ),
        {}
      )
      .then((res: AxiosResponse<[FastJsonList<JobScreenType, string>, JobStageCountType]>) => {
        const [jobScreenData] = res.data
        const job_orders_count = res.data[1]
        dispatch({
          type: JOB_DATA_CHANGE,
          payload: {
            job_orders_count,
            job_orders_data: jobScreenData,
            readyForNext: !!jobScreenData?.meta?.next,
            loadingJobOrders: false,
            firstLoadJobOrdersSuccess: true
          }
        })
        return res
      })
  }

  const fetchJobDetailById = (jobId) => {
    return client.get(`${API_URL_GET_JOB_DETAIL}/${jobId}`, {})
  }

  const fetchJobDetailById4Client = (jobId) => {
    return client.get(`${API_URL_GET_JOB_CONSULTANTS_DETAIL}/${jobId}`, {})
  }

  const fetchJobApplicant = (jobId) => {
    return client.get(API_URL_JOBS_CANDIDATES(jobId), {})
  }

  const updateJobById4Client = (jobId, data) => {
    const { job_languages_attributes, recruiter_ids } = data
    const params = {
      ...data,
      job_languages_attributes: job_languages_attributes.filter((lang) => lang.language !== undefined),
      recruiter_ids: recruiter_ids
    }
    return client.put(`${API_URL_CONSULTANT_CREATE_JOB_4_CLIENT}/${jobId}`, { data: params })
  }

  const updateApplicantsStage = (candidate_id, job_stage_id, new_index) => {
    return client.put(`${API_URL_COMPANY_APPLICANTS_STAGE(candidate_id)}`, { data: { job_stage_id: job_stage_id, new_index: new_index } })
  }
  const updateMatchingApplicant = (profileID, jobId) => {
    return client.put(API_CONSULTANT_MATCH_PROFILE(profileID, jobId), {})
  }
  const updateMatchingMultiApplicant = (profileID, data) => {
    return client.put(API_CONSULTANT_MATCH_MULTIPLE_PROFILE(profileID), { data })
  }
  const createJobNotes = (jobId, data) => {
    const { content } = data
    const params = {
      ...data,
      content: content
    }
    return client.post(API_URL_JOB_NOTES(jobId), { data: params })
  }
  const updateJobNotes = (jobId, id, data) => {
    const { content } = data
    const params = {
      ...data,
      content: content
    }
    return client.put(API_URL_UPDATE_JOB_NOTES(jobId, id), { data: params })
  }
  const deleteJobNotes = (jobId, id) => {
    return client.delete(API_URL_UPDATE_JOB_NOTES(jobId, id), {})
  }
  const deleteJob = (jobId: number) => client.delete(`${API_URL_GET_JOB_DETAIL}/${jobId}`, {})
  const closeJob = (jobId: number) => client.put(`${API_URL_GET_JOB_DETAIL}/${jobId}`, { data: { status: ENUM_JOB_STATUS.closed } })
  const reopenJob = (jobId: number) => client.put(`${API_URL_GET_JOB_DETAIL}/${jobId}`, { data: { status: ENUM_JOB_STATUS.published } })

  const switchFreelance = (jobId: number, freelance) =>
    client.patch(`/api/v2/consultants/job_orders/${jobId}/switch_freelancer`, { data: { freelance } })
  const switchHotJob = (jobId: number, hot_job) => client.post(`/api/v2/consultants/job_orders/${jobId}/set_hot_job`, { data: { hot_job } })
  const updateFreelanceBonnus = (jobId: number, data) =>
    client.patch(`/api/v2/consultants/job_orders/${jobId}/update_freelance_bonus`, { data: data })
  const updatePriority = (jobId: number, priority) =>
    client.patch(`/api/v2/consultants/job_orders/${jobId}/switch_priority`, { data: { status: priority } })
  return {
    jobLevel,
    employmentType,
    jobLangs,
    jobProficiencies,
    fetchListSkills,
    fetchListJobCategories,
    fetchListJobOrders,
    fetchJobDetailById,
    jobStatus,
    createNewJob4Client,
    updateJobById4Client,
    fetchJobDetailById4Client,
    deleteJob,
    closeJob,
    reopenJob,
    updateApplicantsStage,
    fetchJobApplicant,
    createJobNotes,
    updateJobNotes,
    deleteJobNotes,
    switchFreelance,
    switchHotJob,
    updateFreelanceBonnus,
    updatePriority,
    updateMatchingApplicant,
    updateMatchingMultiApplicant
  }
}
