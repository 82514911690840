import { AuthReducer } from './AuthData'
import { BlogReducer, BlogPage } from './BlogData'
import { Action } from '../type/Common'
import { JobReducer, JobScreenType } from './company/JobData'
import { ClientReducer } from './company/clientData'
import { OnboardingReducer, OnboadingType } from './onboardingData'
import { ServerVarReducer } from './serverVar'
import { ServerVarStoreType } from '@legacy/@core/utilities/CollectServerVariableContent'
import { CandidateProfileReducer, CandidateProfileDataType } from './profileData'
import { PublicDataType, PublicDataReducer } from './PublicData'
import { ConsultantJobDetailReducer, ConsultantJobDetailPage } from './ConsultantJobDetailData'
import { HistoryDataType, HistoryReducer } from './HistoryDta'
import { LPReducer, LPType } from './LPCompanyData'

export interface RootStateType {
  auth?: any
  blogPage?: BlogPage
  job?: JobScreenType
  onboarding?: OnboadingType
  serverVar?: ServerVarStoreType
  candidateProfile?: CandidateProfileDataType
  clientCompany?: any
  publicData?: PublicDataType
  consultantJobDetail?: ConsultantJobDetailPage
  history?: HistoryDataType
  lp?: LPType
}

const composeReducer = (reducer) => {
  return (state, action) => {
    return Object.keys(reducer).reduce((result, key) => ({ ...result, [key]: reducer[key](state[key], action) }), {})
  }
}

const RootReducer = (state: RootStateType, action: Action): RootStateType => {
  const subReducer = composeReducer({
    blog: BlogReducer,
    auth: AuthReducer,
    job: JobReducer,
    onboarding: OnboardingReducer,
    candidateProfile: CandidateProfileReducer,
    serverVar: ServerVarReducer,
    clientCompany: ClientReducer,
    publicData: PublicDataReducer,
    consultantJobDetail: ConsultantJobDetailReducer,
    history: HistoryReducer,
    lp: LPReducer
  })
  return subReducer(state, action)
}

export default RootReducer
