// eslint-disable-next-line no-restricted-imports
import { useRouter } from 'next/router'
import React, { useContext, FC } from 'react'
import { AgentDetectType } from '../type/Common'
import { getIsServer } from '../utilities/SSR'
export type ServerBunble = { isMobile?: boolean; agentDetect?: AgentDetectType }
export type ServerService = {
  getCookie: (key: string) => string
  originalUrl: string
  isMobile: boolean
  agentDetect: AgentDetectType
}
const FreeCRootContext = React.createContext<{ serverService?: ServerService; serverBundle?: ServerBunble }>({})
const Provider = FreeCRootContext.Provider
const bundle: ServerBunble = {}
let isSet = false
export const storeServerBundle = (serverBundle: ServerBunble) => {
  if (serverBundle && !isSet) {
    isSet = true
    bundle.isMobile = serverBundle.isMobile
    bundle.agentDetect = serverBundle.agentDetect
  }
}
export const isMoblie = () => {
  return bundle?.isMobile
}
const FreecProvider: FC<{ serverBundle?: ServerBunble; serverService?: ServerService }> = ({ serverBundle, serverService, children }) => {
  storeServerBundle(serverBundle)
  return <Provider value={{ serverService, serverBundle: bundle }}>{children}</Provider>
}

export const useServerService = () => {
  const { serverService } = useContext(FreeCRootContext)
  return serverService
}
export const useGetAppRealPath = () => {
  const serverService = useServerService()
  const router = useRouter()
  return () => {
    return getIsServer() ? (serverService ? serverService.originalUrl : router?.pathname) : window.location.pathname
  }
}
export const useUserAgent = () => {
  const serverService = useServerService()
  return getIsServer() ? serverService.agentDetect : bundle.agentDetect
}
export default FreecProvider
