import { useCurrentUser } from '@legacy/@core/hooks/CurrentUserHooks'
import { useJWTTokenPayload } from '@legacy/@core/hooks/JWTHooks'
import React, { FC, useEffect } from 'react'

const GleapWidget: FC = () => {
  const jwtokenPayload = useJWTTokenPayload()
  const [currentUser] = useCurrentUser()

  useEffect(() => {
    if (jwtokenPayload && jwtokenPayload.user_type !== undefined) {
      if (currentUser?.profile) {
        //@ts-ignore
        if (typeof window?.Gleap === 'function') {
          //@ts-ignore
          window?.Gleap.identify(
            currentUser?.profile.id,
            {
              name: currentUser?.profile.full_name,
              email: currentUser?.profile.email
            },
            'GENERATED_USER_HASH'
          )
          //@ts-ignorepass
          window?.Gleap.registerCustomAction((customAction) => {
            if (customAction.name === 'openCrispChat') {
              if (window.$crisp) {
                window.$crisp.push(['do', 'chat:open'])
              }
            }
          })
        }
      }
    }

    return () => {
      // consolog aim to destroy
    }
  }, [jwtokenPayload?.user_type, currentUser?.profile])
  return <></>
}
export default GleapWidget
