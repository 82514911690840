import { ServerVarStoreType } from '@legacy/@core/utilities/CollectServerVariableContent'
import { Action } from '../type/Common'

export const CHANGE_SERVER_VAR = 'CHANGE_SERVER_VAR'
export const ServerVarReducer = (state: ServerVarStoreType, action: Action): ServerVarStoreType => {
  switch (action.type) {
    case CHANGE_SERVER_VAR: {
      return action.payload
    }
    default: {
      return state
    }
  }
}
