import { useCallback, useMemo, useState } from 'react'

export function ignoreFirst(func) {
  let isFist = false
  return function () {
    const context = this
    // eslint-disable-next-line prefer-rest-params
    const args = arguments

    if (!isFist) {
      isFist = true
      return
    }
    return func.apply(context, args)
  }
}
export const createSafeAction = (action: () => Promise<any>) => {
  let loading = false
  return () => {
    if (!loading) {
      loading = true
      return action().then((rs) => {
        loading = false
        return rs
      })
    } else {
      return Promise.resolve()
    }
  }
}
export function takeLast(func, wait): any {
  let timeout
  return function executedFunction() {
    const context = this
    // eslint-disable-next-line prefer-rest-params
    const args = arguments
    const later = function () {
      timeout = null

      clearTimeout(timeout)
      func.apply(context, args)
    }
    if (timeout) {
      clearTimeout(timeout)
    }
    timeout = setTimeout(later, wait)
  }
}

export function takeOnePromise<ARG extends any[], T>(func: (...args: ARG) => Promise<T>): (...args: ARG) => Promise<T> {
  let state: 'created' | 'excuting' | 'finished' = 'created'
  const listResolveCallBack = []
  return function executedFunction(...args: ARG) {
    if (state !== 'excuting') {
      state = 'excuting'
      return func(...args)
        .then((rs) => {
          while (listResolveCallBack.length > 0) {
            listResolveCallBack.pop().resolve(rs)
          }
          return rs
        })
        .finally(() => {
          state = 'finished'
        })
    }
    return new Promise<T>((resolve, reject) => {
      listResolveCallBack.push({ resolve, reject })
    })
  }
}
type AsyncActionFn = (...args: any[]) => Promise<any>

export function useLoadingAction<T extends AsyncActionFn>(
  action: T,
  deps: any[] = []
): [(...args: Parameters<T>) => Promise<any>, boolean] {
  const [loading, setLoading] = useState(false)

  const run = useCallback(
    (...args: Parameters<T>) => {
      if (loading) {
        return
      }

      setLoading(true)

      return action(...args).finally(() => {
        setLoading(false)
      })
    },
    [loading]
  )

  return useMemo(() => [run, loading], [run, loading, ...deps])
}
