import { useCurrentUser } from '@legacy/@core/hooks/CurrentUserHooks'
import { useJWTIsLogin, useJWTRole, useJWTTokenPayload } from '@legacy/@core/hooks/JWTHooks'
import { useClient } from '@legacy/@core/hooks/useClient'
import { useTranslation } from '@legacy/@core/hooks/UserTranslation'
import { API_URL_CONSULTANT_GET_JOBS } from '@legacy/@core/utilities/APIConstant'
import { AxiosResponse } from 'axios'
import { useMemo } from 'react'
import { useCurrentCompany } from 'hooks/CurrentCompanyHooks'
import { useLocalStorageState } from '../hooks/share/StorageHooks'
import { CandidateProfileCVSerializer, ProfileType } from '../type/CandidateProfile'
import { Action, ClientResponse } from '../type/Common'
import { API_URL_FETCH_ME, API_URL_GET_PROFILE_COMPANY, API_URL_UPDATE_PASSWORD } from '../utilities/APIConstant'
import { getIsServer } from '../utilities/common'
import { COOKIES_NAME_LANGUAGE } from '../utilities/Constant'
import { deleteCookie, setCookie } from '../utilities/Cookies'
import { useData } from './store'

export const AUTHEN_STATE_CHANGE = 'AUTHEN_STATE_CHANGE'
const X_AUTH_TOKEN = 'X_AUTH_TOKEN'
const X_LANGUAGE = 'X_LANGUAGE'

export type ApplyProcessType = {
  option?: number
  job_id?: number
  job_title?: string
  company_name?: string
  file?: any
  step?: number
  locations?: { id: number; state: string; state_vn: string }
}

export interface AuthType {
  // isCredentialValid?: boolean
  // user_type?: number
  login?: boolean
  profile?: ProfileType
  profile_fetch?: boolean
  email?: string
  user_id?: number
  message?: string
  showRegisterSuccessModal?: boolean
  profileApply?: CandidateProfileCVSerializer
  applyProcess?: ApplyProcessType
  // publicUserMeta?: PublicSystemUserMeta
}
const getIsshowRegisterSuccessModal = () => {
  if (getIsServer()) {
    return false
  }
  return (
    (localStorage.getItem('is_register_success') || '').trim() === 'true' &&
    (localStorage.getItem('closed_register_success_modal') || '').trim() !== 'true'
  )
}
export const getDefaultAuthStore = (): AuthType => ({
  login: false,
  showRegisterSuccessModal: getIsshowRegisterSuccessModal(),
  profile_fetch: false
})
export const AuthReducer = (state: AuthType = getDefaultAuthStore(), action: Action): AuthType => {
  switch (action.type) {
    case AUTHEN_STATE_CHANGE: {
      return { ...state, ...action.payload }
    }
    default: {
      return state
    }
  }
}

/**
 * @deprecated
 * use useCurrentUser for current user information
 */
export const useAuthenData = (): {
  auth?: any
  indexPath: string
  isLogined: boolean
  fetchUserProfile: () => Promise<ProfileType>
  getToken: () => string | null
  logout: () => Promise<any>
  login: (vaules: any) => Promise<ClientResponse>
  setVerifyData: (args: { email: string; user_id: number }) => void
  verifyData: { email: string; user_id: number }
  setVerifyMessage: (args: { message: string }) => void
  verifyMessage: { message: string }
  registerSuccess: () => void
  closeRegisterSuccessModal: () => void
  getProfileCompany: () => Promise<ClientResponse>
  updatePassword: (data) => Promise<ClientResponse>
  isCredentialValid: boolean
  accessToken: (data) => void
  userProfileApply: (data) => void
  applyProcessInfo: (data: ApplyProcessType) => void
  profile_fetch: boolean
  user_id?: number
  role: 'candidate' | 'admin' | 'company' | 'consultant' | 'anonymous'
  user_type: number
  isOnboardingSuccess: boolean
  current_company_id?: number
} => {
  const { state, dispatch } = useData()
  const { client } = useClient()
  const [applyProcess, setApplyProcess] = useLocalStorageState<ApplyProcessType>()
  const [currentUser] = useCurrentUser()
  const [currentCompany] = useCurrentCompany()
  const auth = useMemo(() => ({ ...state?.auth, profile: currentUser?.profile, applyProcess }), [state?.auth, applyProcess, currentUser])
  const { fromNumberToCode } = useTranslation()
  const applyProcessInfo = (arg: ApplyProcessType) => {
    setApplyProcess(arg)
  }

  const userProfileApply = (profileApply) => {
    return dispatch({ type: AUTHEN_STATE_CHANGE, payload: { profileApply } })
  }

  const fetchUserProfile = () => {
    return client.get(API_URL_FETCH_ME, {}).then((response) => {
      const profile = response.data.data
      setCookie(X_LANGUAGE, profile.language === 0 ? 'en' : 'vi', 10000)
      dispatch({ type: AUTHEN_STATE_CHANGE, payload: { profile, profile_fetch: true } })
      return profile
    })
  }

  const getToken = () => client.getToken()
  const logout = () => {
    return client.logout().then(() => {
      // dispatch({ type: AUTHEN_STATE_CHANGE, payload: { profile_fetch: false, profile: undefined } })
      //@ts-ignore
      if (typeof window?.Gleap === 'function') {
        //@ts-ignore
        window.Gleap.clearIdentity()
      }
      localStorage.setItem('is_register_success', 'false')
      localStorage.setItem('closed_register_success_modal', 'false')
      deleteCookie(X_LANGUAGE)
      dispatch({ type: AUTHEN_STATE_CHANGE, payload: { profile: undefined } })
    })
  }
  const login = (values) => {
    return client.login(values, false).then((rs) => {
      return client.get(API_URL_FETCH_ME, {}).then((response: AxiosResponse<ProfileType>) => {
        const profileLang = response.data.language
        setCookie(COOKIES_NAME_LANGUAGE, fromNumberToCode(profileLang))
        return rs
      })
    })
  }

  const setVerifyData = ({ email, user_id }) => {
    dispatch({ type: AUTHEN_STATE_CHANGE, payload: { email, user_id } })
  }
  const verifyData = { email: auth?.email, user_id: auth?.user_id }
  const setVerifyMessage = ({ message }) => {
    dispatch({ type: AUTHEN_STATE_CHANGE, payload: { message } })
  }
  const verifyMessage = { message: auth?.message }
  const registerSuccess = () => {
    localStorage.setItem('is_register_success', 'true')
    return dispatch({ type: AUTHEN_STATE_CHANGE, payload: { showRegisterSuccessModal: getIsshowRegisterSuccessModal() } })
  }
  const closeRegisterSuccessModal = () => {
    localStorage.setItem('closed_register_success_modal', 'true')
    return dispatch({ type: AUTHEN_STATE_CHANGE, payload: { showRegisterSuccessModal: getIsshowRegisterSuccessModal() } })
  }

  const getProfileCompany = () => {
    return client.get(API_URL_GET_PROFILE_COMPANY, {})
  }

  const updatePassword = (data) => {
    return client.post(API_URL_UPDATE_PASSWORD, { data, config: { preventRedirect: true } })
  }
  const accessToken = (response) => {
    const accessToken = response.data.token
    const token = `Bearer ${accessToken}`
    localStorage.setItem('AUTHENTICATION_TOKEN', token)
    setCookie(X_AUTH_TOKEN, accessToken, 10000)
  }
  const isLogin = useJWTIsLogin()
  const JWTToken = useJWTTokenPayload()
  const isLogined = !!isLogin
  const profile_fetch = !!(auth && auth.profile_fetch)
  const role = useJWTRole()
  const indexPath = API_URL_CONSULTANT_GET_JOBS

  const isOnboardingSuccess = false
  const current_company_id = currentCompany?.company?.id

  return {
    auth,
    indexPath,
    fetchUserProfile,
    getToken,
    logout,
    login,
    setVerifyData,
    verifyData,
    setVerifyMessage,
    verifyMessage,
    registerSuccess,
    closeRegisterSuccessModal,
    getProfileCompany,
    updatePassword,
    isLogined,
    isCredentialValid: JWTToken?.isCredentialValid,
    accessToken,
    profile_fetch,
    user_id: JWTToken?.user_id,
    role,
    user_type: JWTToken?.user_type,
    isOnboardingSuccess,
    current_company_id,
    userProfileApply,
    applyProcessInfo
  }
}
