import { API_URL_LIST_TEAM_MEMBER } from 'utilities/APIConstant'
import client from '@legacy/@core/utilities/Client'

// export const getPublicTeamMemberAPI: (key?: string) => Promise<FastJsonList<MemberIndexType>> = debounce(
//   (key: string) =>
//     client
//       .get(API_URL_LIST_TEAM_MEMBER(parsePramsToQueryString({ active: true, all: 1 })), { params: { key } })
//       .then((res: AxiosResponse<FastJsonList<MemberIndexType>>) => {
//         return res.data
//       }),
//   400,
//   {
//     leading: true,
//     trailing: false
//   }
// )

export const getTeamMemberAPI = (params?: any) => {
  return client.get(API_URL_LIST_TEAM_MEMBER, { params })
}
