import css from 'styled-jsx/css'

export const UtilitiesStyle = css.global`
  .my-2 {
    margin: 8px 0;
  }
  .py-2 {
    padding: 8px 0;
  }
  .pt-2 {
    padding-top: 4px;
  }
  .hover\:color\[\#1890ff\]:hover {
    color: #1890ff;
  }
`
