import { SkillYearType } from '../type/Common'

export const skill_years: SkillYearType[] = [
  { year: 0, name: 'Less than 1 year ', name_vn: 'Ít hơn 1 năm' },
  { year: 1, name: '1 year', name_vn: '1 năm' },
  { year: 2, name: '2 years', name_vn: '2 năm' },
  { year: 3, name: '3 years', name_vn: '3 năm' },
  { year: 4, name: '4 years', name_vn: '4 năm' },
  { year: 5, name: '5 years', name_vn: '5 năm' },
  { year: 6, name: '6 years', name_vn: '6 năm' },
  { year: 7, name: '7 years', name_vn: '7 năm' },
  { year: 8, name: '8 years', name_vn: '8 năm' },
  { year: 9, name: '9 years', name_vn: '9 năm' },
  { year: 10, name: '10+ years', name_vn: '10+ năm' }
]
