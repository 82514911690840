import css from 'styled-jsx/css'

export const SidebarStyle = css.global`
  .sidebar {
    display: none;
  }

  @media screen and (max-width: 1023.98px) {
    .sidebar {
      position: fixed;
      z-index: 999;
      width: 100%;
      background: #fff;
      min-height: var(--layout-header-height);
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 16px;
    }

    .sidebar .none-shadow {
      box-shadow: none;
    }

    .sidebar .logo {
    }

    .sidebar .logo .header-logo-img {
      height: 34px;
    }

    .sidebar .logo .btn-link {
      margin-right: 64px;
      background-color: #fff;
      padding: 0;
      border: none;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #595959;
    }
    .sidebar .logo .mr-0 {
      margin-right: 0;
    }

    .sidebar #nav-icon {
      transition: 0.5s ease-in-out;
      cursor: pointer;
      z-index: 555;
      background-color: transparent;
      border-color: transparent;
      border-radius: 50%;
      width: 34px;
      padding: 7px 0px 5px 4px;
      background-color: #fff;
    }

    .sidebar #nav-icon:focus {
      outline: none;
    }

    .sidebar #nav-icon .wrap-icon {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 16px;
    }

    .sidebar #nav-icon .wrap-icon span {
      width: 22px;
      height: 2px;
      display: block;
      background: #262626;
      transition: all 0.4s ease-in-out;
    }

    .sidebar #nav-icon.open span:nth-child(1) {
      transform: translate(0, 6px) rotate(45deg);
      background-color: #262626;
    }

    .sidebar #nav-icon.open span:nth-child(2) {
      opacity: 0;
    }

    .sidebar .notification-btn-open {
      margin: 0 !important;
    }

    .sidebar #nav-icon.open span:nth-child(3) {
      transform: translate(0, -8px) rotate(-45deg);
      background-color: #262626;
    }

    .sidebar .mr-24-px {
      margin-right: 24px;
    }

    .drawer__title {
      font-size: 16px;
      line-height: 24px;
      font-weight: bold;
    }

    .sidebar__info-account {
      padding: 16px;
      border-top: 1px solid #e8e8e8;
      border-bottom: 1px solid #e8e8e8;
      color: #141921;
    }

    .sidebar-account__name {
      font-weight: bold;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .sidebar-account__role {
      font-size: 12px;
      line-height: 20px;
    }

    .sidebar-account__email {
      color: #7a7c82;
      font-size: 12px;
      line-height: 20px;
    }

    .sidebar-body {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: calc(100% - 96px);
    }

    body .ant-drawer-footer,
    body .ant-drawer-footer .btn-link {
      background-color: #f6f8fc !important;
    }

    body .ant-drawer-body .btn-link,
    body .ant-drawer-footer .btn-link {
      padding: 13px 16px !important;
      font-weight: 500;
      font-size: 14px !important;
      line-height: 22px !important;
      color: #141921 !important;
    }

    body .ant-drawer-body .group-link {
      padding: 8px 0;
      border-bottom: 1px solid #e8e8e8;
    }

    body .ant-drawer-body .group-link:last-child {
      border-bottom: none;
    }

    .btn-log-out {
      padding: 13px 16px !important;
      height: unset !important;
      width: 100% !important;
      text-align: left !important;
      color: #141921 !important;
      display: flex !important;
      border-right: none !important;
      border-left: none !important;
      border-bottom: none !important;
      border-top: 1px solid #e8e8e8 !important;
    }

    .ant-btn > .anticon + span {
      margin-left: 12px !important;
    }

    .bb-feedback-button {
      display: none !important;
    }

    .crisp-client {
      display: none !important;
    }
  }

  body .btn-close-sidebar {
    border: none;
    box-shadow: unset;
    font-size: 20px;
    padding: 0;
  }

  body .ant-drawer-open {
    z-index: 1000;
  }

  body .ant-drawer-body {
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  body .ant-drawer-body .btn-link,
  body .ant-drawer-footer .btn-link {
    background-color: #fff;
    padding: 0;
    border: none;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #595959;
    display: flex;
    align-items: center;
    text-transform: capitalize;
  }

  body .mr-12-px {
    margin-right: 12px;
  }

  body .ant-drawer-body .group-link p {
    margin-bottom: 24px;
  }
  body .ant-drawer-body .view-company-name {
    display: flex;
    flex-direction: column;
  }

  body .ant-drawer-body .view-company-name .label-career-site {
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #595959;
  }

  body .ant-drawer-body .view-company-name .label-company-name {
    font-family: Roboto;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #595959;
    margin-top: 4px;
  }

  body .ant-drawer-body .view-company-name .label-company-name:hover {
    color: #1890ff;
  }

  body .ant-drawer-body .group-button .btn-log-out {
    text-align: left;
    padding: 0;
    border: none;
    box-shadow: unset;
    font-size: 18px;
    line-height: 24px;
    color: #595959;
    font-weight: 500;
  }
  body .ant-drawer-body .group-button .btn-log-out .btn-log-out-text {
    margin-left: 0;
  }
  body .ant-drawer-body .group-button-before {
    height: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  body .ant-drawer-body .group-button-employer {
    height: 80%;
  }

  body .ant-drawer-body .group-button .btn-employer {
    padding: 0;
    border: none;
    text-align: left;
    box-shadow: none;
    margin-top: 30px;
  }
  body .ant-drawer-body .group-button .btn-employer .title {
    font-size: 20px;
    color: #1890ff;
    font-weight: 500;
  }
  body .ant-drawer-body .group-button .btn-employer .sub-title {
    font-size: 14px;
    color: #8c8c8c;
  }
  body .ant-drawer-body .tag-beta {
    display: inline-block;
    padding: 2px 5px;
    border-radius: 2px;
    background-color: #ff4d4f;
    margin-left: 8px !important;
    font-size: 8px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    text-transform: uppercase;
  }
  body .ant-drawer-body .ant-divider {
    background-color: #e8e8e8 !important;
  }
  body .ant-drawer-body .download {
    margin-top: 53px;
  }

  body .ant-drawer-body .download .logo-download {
    height: 36px;
  }

  body .ant-drawer-body .download .logo-download img {
    width: 100%;
  }

  body .ant-drawer-body .icon {
    margin-right: 22px;
  }

  body .sidebar-collapse {
    margin-bottom: 24px;
  }

  body .sidebar-collapse .ant-collapse-item {
    border: none;
  }

  body .sidebar-collapse .ant-collapse-header {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #595959 !important;
    padding-left: 0 !important;
    background-color: #fff !important;
    padding-bottom: 24px;
  }

  body .sidebar-collapse .sub-menu {
    font-size: 16px;
    line-height: 24px;
    color: #595959;
    font-weight: normal;
    text-transform: lowercase;
  }
  body .ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content {
    background-color: #fff;
  }

  body .ant-drawer-footer {
    padding: 0;
    border: none;
  }

  body .ant-drawer-footer .sidebar-lang {
    display: flex;
    align-items: center;
    padding: 24px;
  }

  body .ant-drawer-footer .sidebar-lang .btn-lang {
    flex: 1;
    background-color: #fff;
    padding: 12px 36px;
    border: 1px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
  }
  body .ant-drawer-footer .sidebar-lang span {
    font-size: 18px;
    color: #595959;
    margin-left: 8px;
  }

  body .ant-drawer-footer .sidebar-lang .activeLang {
    background-color: #e6f7ff;
    border-color: #e6f7ff;
  }

  body .ant-drawer-header {
    border-bottom: none;
    padding: 16px;
  }
  body .ant-drawer-footer .btn-employer {
    background-color: #fff;
    padding: 24px;
    border: none;
    text-align: left;
    box-shadow: none;
    display: flex;
    width: 100%;
  }
  body .ant-drawer-footer .btn-employer .icon-left {
    padding: 11px;
    background-color: #e6f7ff;
    margin-right: 8px;
    border-radius: 50%;
  }

  body .ant-drawer-footer .btn-employer .title {
    font-size: 18px;
    color: #1890ff;
    font-weight: 500;
    text-transform: uppercase;
  }

  body .ant-drawer-footer .btn-employer .sub-title {
    font-size: 14px;
    color: #262626;
  }

  body .ant-drawer-footer .btn-employer .icon-right {
    margin-left: auto;
  }
  .sidebar .view-company-name {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 64px;
  }

  .sidebar .view-company-name .label-career-site {
    font-family: Roboto;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #595959;
  }

  .sidebar .view-company-name .label-company-name {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #595959;
    margin-top: 4px;
  }

  .sidebar .view-company-name .label-company-name:hover {
    color: #1890ff;
  }
`
