import { NEXT_PUBLIC_APP_ENVIRONMENT, NEXT_PUBLIC_FIREBASE_FCM_PUBLIC_KEY } from 'utilities/env'

const FirebaseMessage = () => import('firebase/messaging')
const Firebase = () => import('firebase/app')
const Database = () => import('firebase/database')
const Auth = () => import('firebase/auth')
export declare interface FcmOptions {
  /**
   * The link to open when the user clicks on the notification.
   */
  link?: string
  /**
   * The label associated with the message's analytics data.
   */
  analyticsLabel?: string
}
export declare interface NotificationPayload {
  /**
   * The notification's title.
   */
  title?: string
  /**
   * The notification's body text.
   */
  body?: string
  /**
   * The URL of an image that is downloaded on the device and displayed in the notification.
   */
  image?: string
}
interface MessagePayload {
  /**
   * {@inheritdoc NotificationPayload}
   */
  notification?: NotificationPayload
  /**
   * Arbitrary key/value payload.
   */
  data?: {
    [key: string]: string
  }
  /**
   * {@inheritdoc FcmOptions}
   */
  fcmOptions?: FcmOptions
  /**
   * The sender of this message.
   */
  from: string
  /**
   * The collapse key of the message. See
   * {@link https://firebase.google.com/docs/cloud-messaging/concept-options#collapsible_and_non-collapsible_messages | Non-collapsible and collapsible messages}
   */
  collapseKey: string
  /**
   * The message id of a message.
   */
  messageId: string
}

const STAING_CONFIG = {
  apiKey: 'AIzaSyDkMCMlwVwscTqwEkzfm8_LhwLFolAwDDs',
  authDomain: 'freec2-stag.firebaseapp.com',
  databaseURL: 'https://freec2-stag.firebaseio.com',
  projectId: 'freec2-stag',
  storageBucket: 'freec2-stag.appspot.com',
  messagingSenderId: '491021860021',
  appId: '1:491021860021:web:d1e2386bf59f19407e12fc',
  measurementId: 'G-R7BRL48ESX'
}
const PRODUCTION_CONFIG = {
  apiKey: 'AIzaSyA2nojwNQO7JU7AgaAS-LqVBgzuInfh58E',
  authDomain: 'freec2.firebaseapp.com',
  databaseURL: 'https://freec2.firebaseio.com',
  projectId: 'freec2',
  storageBucket: 'freec2.appspot.com',
  messagingSenderId: '218455232617',
  appId: '1:218455232617:web:ba841ac1b7f3a865e252f8',
  measurementId: 'G-QSLEK8S1GR'
}
const getRegisteration = () => {
  if ('serviceWorker' in navigator) {
    return navigator.serviceWorker.register(
      { ['staging']: '/freec-stag-service-worker.js', ['production']: '/freec-prod-service-worker.js' }[NEXT_PUBLIC_APP_ENVIRONMENT] ||
        '/freec-stag-service-worker.js',
      { scope: '/' }
    )
  } else {
    return Promise.reject('firebase_message_not_support')
  }
}

let firebaseApp
const getSingleApp = () => {
  if (firebaseApp) return Promise.resolve(firebaseApp)
  return Firebase().then((firebase) => {
    firebaseApp = firebase.initializeApp(
      { ['staging']: STAING_CONFIG, ['production']: PRODUCTION_CONFIG }[NEXT_PUBLIC_APP_ENVIRONMENT] || STAING_CONFIG
    )
    return firebaseApp
  })
}
export const getFirebaseDatabase = () =>
  getSingleApp().then((app) => {
    return Database().then((database) => database.getDatabase(app))
  })
export const getFirebaseAuth = () =>
  getSingleApp().then((app) => {
    return Auth().then(({ getAuth, signInWithCustomToken }) => {
      const auth = getAuth(app)
      return {
        signInWithCustomToken: (token: string) => signInWithCustomToken(auth, token),
        currentUser: auth.currentUser
      }
    })
  })

export const getFirebaseMessage = () =>
  getRegisteration().then((registration) =>
    Firebase().then((firebase) => {
      firebase.initializeApp(
        { ['staging']: STAING_CONFIG, ['production']: PRODUCTION_CONFIG }[NEXT_PUBLIC_APP_ENVIRONMENT] || STAING_CONFIG
      )
      return FirebaseMessage().then(({ getMessaging, getToken, onMessage, isSupported }) => {
        return isSupported().then((isSupported) => {
          if (!isSupported) {
            throw 'firebase_message_not_support'
          }
          return { getMessaging, getToken, registration, onMessage }
        })
      })
    })
  )
export const isFirebaseSuppored = () =>
  getRegisteration().then(() =>
    Firebase().then((firebase) => {
      firebase.initializeApp(
        { ['staging']: STAING_CONFIG, ['production']: PRODUCTION_CONFIG }[NEXT_PUBLIC_APP_ENVIRONMENT] || STAING_CONFIG
      )
      return FirebaseMessage().then(({ isSupported }) => {
        return isSupported()
      })
    })
  )

export const getFirebaseMessageFCMToken = () =>
  getFirebaseMessage()
    .then(({ getMessaging, getToken, registration }) => {
      const messaging = getMessaging()
      return getToken(messaging, { vapidKey: NEXT_PUBLIC_FIREBASE_FCM_PUBLIC_KEY, serviceWorkerRegistration: registration })
    })
    .catch((e) => {
      if (e !== 'firebase_message_not_support') {
        throw e
      }
    })

export const registerFirebaseMessage = (handleMessage: (payload: MessagePayload) => void) =>
  getFirebaseMessage()
    .then(({ getMessaging, onMessage }) => {
      const messaging = getMessaging()
      return onMessage(messaging, handleMessage)
    })
    .catch((e) => {
      if (e !== 'firebase_message_not_support') {
        throw e
      }
    })

export const useRegisterServiceWorker = () => {
  return getRegisteration
}
