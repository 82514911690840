/* eslint-disable @next/next/inline-script-id */
import { useCurrentUser } from '@legacy/@core/hooks/CurrentUserHooks'
import { useJWTRole, useJWTTokenPayload } from '@legacy/@core/hooks/JWTHooks'
import * as Sentry from '@sentry/node'
import { useCurrentCompany } from 'hooks/CurrentCompanyHooks'
import { useRouter } from 'next/router'
import Script from 'next/script'
import React, { FC, useCallback, useEffect } from 'react'
import { createNewCompanyIdSlug } from './common'

// const intallCrisp = (onloaData: () => void, tokenId: string | number | undefined) => {
//   const id = 'crisp-lib'
//   const script = () => {
//     const script = document.createElement('script')
//     script.async = true
//     script.type = 'text/javascript'
//     script.src = 'https://client.crisp.chat/l.js'
//     script.id = id
//     document.head.appendChild(script)
//     script.addEventListener('load', onloaData, false)
//     return script
//   }

//   if (document.getElementById(id)) {
//     window.CRISP_TOKEN_ID = tokenId
//     window.$crisp.push(['do', 'session:reset'])
//     onloaData()
//     window.$crisp.push(['do', 'chat:show'])
//     return
//   }
//   const emptyArray = []
//   window.$crisp = emptyArray
//   window.CRISP_WEBSITE_ID = process.env.NEXT_PUBLIC_CRISP_WEBSITE_ID
//   document.head.appendChild(script())
// }
const IGNORE_PATH = ['^/consultants/chat']
const isIgnore = () => IGNORE_PATH.find((path) => location.pathname.match(path))

const CrispChat: FC = () => {
  const allowUserType = process.env.NEXT_PUBLIC_ENABLE_LIVE_CHAT_USER_TYPE?.split(',').map((stri) => parseInt(stri))
  const currentRole = useJWTRole()
  const jwtokenPayload = useJWTTokenPayload()
  const [currentUser] = useCurrentUser()
  const configType = { candidate: 1, company: 2, consultant: 3, anonymous: 4 }[currentRole] || 4
  const [currentCompany] = useCurrentCompany()
  const router = useRouter()

  const onScriptLoad = useCallback(() => {
    if (window.$crisp) {
      if (jwtokenPayload && jwtokenPayload.user_type !== undefined && allowUserType.includes(configType) && !isIgnore()) {
        if (currentUser?.profile && currentCompany?.company) {
          try {
            window.$crisp.push(['set', 'user:email', currentUser.profile?.email])
            window.$crisp.push(['set', 'user:nickname', currentUser.profile?.full_name])
            window.$crisp.push(['set', 'user:phone', currentUser.profile?.phone_number])
            window.$crisp.push(['set', 'user:avatar', currentUser.profile?.photo_url])
            window.$crisp.push(['set', 'session:segments', [[currentRole === 'company' ? 'recruiter' : currentRole, 'web-chat']]])
            window.$crisp.push(['set', 'session:data', [[['freec_user_id', currentUser.profile?.id]]]])
            if (currentCompany?.company) {
              window.$crisp.push([
                'set',
                'session:data',
                [
                  [
                    [
                      'freec_company_slug',
                      `${process.env.NEXT_PUBLIC_APP_DOMAIN}/company/${createNewCompanyIdSlug(
                        currentCompany?.company.slug,
                        currentCompany?.company.id
                      )}`
                    ],
                    ['website_url', currentCompany?.company.website_url]
                  ]
                ]
              ])
            }
          } catch (error) {
            Sentry.captureException(error)
          }
          // intallCrisp(onLoad, currentUser.profile?.id)
        } else {
          window.$crisp.push(['do', 'chat:close'])
        }
      } else {
        // anonymous user goes here
        if (allowUserType.includes(4) && !isIgnore()) {
          // intallCrisp(() => {}, undefined)
        } else {
          window.$crisp.push(['do', 'chat:hide'])
        }
      }
    }
    return () => {
      // consolog aim to destroy
    }
  }, [currentUser])

  useEffect(() => {
    onScriptLoad()
  }, [jwtokenPayload?.user_type, currentUser?.profile, currentCompany?.company, router.pathname])

  return (
    <>
      <Script>
        {`
         window.$crisp = []
         window.CRISP_WEBSITE_ID = "${process.env.NEXT_PUBLIC_CRISP_WEBSITE_ID}"
        `}
      </Script>
      <Script id="crisp-lib" src="https://client.crisp.chat/l.js" strategy="lazyOnload" onLoad={onScriptLoad} />
    </>
  )
}

export default CrispChat
