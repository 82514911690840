import { createSimpleEmitter } from '@legacy/@core/utilities/Emitter'
import { useEffect, useState } from 'react'
import { getIsServer } from '../../utilities/common'
const { emitAll, regiterCallback, unregisterCallBack } = createSimpleEmitter<any>()
export const useLocalStorageState = <T>(keyname = '@apply_process'): [T, (srg: T) => void, () => void] => {
  const [state, setState] = useState<T>(
    getIsServer() ? {} : localStorage.getItem(keyname) ? JSON.parse(localStorage.getItem(keyname)) : undefined
  )
  const setLocalStorageState = (state: T) => {
    localStorage.setItem(keyname, state ? JSON.stringify(state) : '')
    emitAll(state)
  }
  const onEvent = (args) => {
    return Promise.resolve(setState(args))
  }
  useEffect(() => {
    regiterCallback(onEvent)
    return () => {
      unregisterCallBack(onEvent)
    }
  }, [])
  return [
    state,
    setLocalStorageState,
    () => {
      localStorage.setItem(keyname, '')
      setState(undefined)
    }
  ]
}
