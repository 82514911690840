import { getListAccountManagerAPI } from 'service/api/common/account-managers.api'
import { getClientsAPI, submitAssignClientsAPI, submitCreateClientsAPI } from 'service/api/consultants/clients.api'
import { ClientSearchType } from '../../type/ClientType'
import { Action, ClientResponse } from '../../type/Common'
import { useData } from '../store'

const CLIENT_DATA_CHANGE = 'CLIENT_DATA_CHANGE'

export const ClientReducer = (state: any = { readyForNext: false, loadingJob: true, firstLoadSuccess: false }, action: Action): any => {
  switch (action.type) {
    case CLIENT_DATA_CHANGE: {
      return { ...state, ...action.payload }
    }
    default:
      return state
  }
}

export const useClientData = (): {
  companyClient: any
  createClient: (data) => Promise<ClientResponse>
  getClients: (searchValue: ClientSearchType) => Promise<ClientResponse>
  assignClient: (searchValue) => Promise<ClientResponse>
  getListAccountManager: () => Promise<ClientResponse>
} => {
  // todo: Refactoring code below for readability
  // separate concern on paging and job fetching
  const { state } = useData()
  const companyClient = state?.clientCompany

  const getClients = (searchValue) => {
    return getClientsAPI({ searchValue })
  }

  const createClient = (data) => {
    return submitCreateClientsAPI({ data })
  }

  const assignClient = (data) => {
    return submitAssignClientsAPI({ data })
  }

  const getListAccountManager = () => {
    return getListAccountManagerAPI()
  }

  return {
    companyClient,
    createClient,
    getClients,
    assignClient,
    getListAccountManager
  }
}
