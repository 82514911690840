import { FastJsonList } from '@legacy/@core/type/Common'
import { ClientBaseType } from 'type/ClientType'
import { ClientSelectViewType, ClientsServer } from 'type/server/consultants/clients.type'

const mapping = ({ record }) => {
  return {
    key: record?.id,
    id: record?.id,
    companyID: record?.company_id,
    companyName: record?.name,
    logo: record?.logo,
    locationState: record?.locations?.data?.attributes.state,
    signedDate: record?.signed_date || '',
    score: record?.score,
    am: (record?.account_managers?.data || []).map((am) => {
      return {
        fullName: am?.attributes?.full_name,
        email: am?.attributes?.email,
        photoUrl: am?.attributes?.photo_url
      }
    }),
    bd: (record?.bd_in_charges?.data || []).map((bd) => {
      return {
        fullName: bd?.attributes?.full_name,
        email: bd?.attributes?.email,
        photoUrl: bd?.attributes?.photo_url
      }
    }),
    openJob: record?.job_posts,
    jobOrder: record?.job_orders,
    placementNumber: record?.placement_number,
    lastNote: record?.last_note,
    lastActivity: record?.last_activity,
    cvSent: record?.cv_sent,
    contractStatus: record?.contract_status,
    status: record?.status,
    clientType: record?.client_type,
    source: record?.source,
    nda: record?.nda
  }
}

export const transformListClients = ({ response }: { response: Array<ClientsServer> }) => {
  return response.map((record) => {
    return mapping({ record })
  })
}

export const mappingClientSelect = (data: FastJsonList<ClientBaseType>): ClientSelectViewType[] => {
  return data.data.map((item) => ({ client_id: item.attributes.id, name: item.attributes.name }))
}
