import React, { useContext, useReducer, FC } from 'react'
import RootReducer, { RootStateType } from './Reducer'
import { getDefaultAuthStore } from './AuthData'
import { getDefaultOnboarding } from './onboardingData'
import { ServerVarStoreType } from '@legacy/@core/utilities/CollectServerVariableContent'
import { getDefaultCandidateProfile } from './profileData'

const DataContext = React.createContext<{ state?: RootStateType; dispatch?: any; getCookie?: (key: string) => string }>({})
const Provider = DataContext.Provider
export const ContextProvider = Provider
export const DataProvider: FC<{
  serverVar: ServerVarStoreType
  getCookie: (key: string) => string
}> = ({ children, serverVar, getCookie }) => {
  const [state, dispatch] = useReducer(RootReducer, {
    auth: { ...getDefaultAuthStore() },
    job: { readyForNext: false, loadingJob: true, firstLoadSuccess: false, loadingJobOrders: true, firstLoadJobOrdersSuccess: false },
    onboarding: getDefaultOnboarding(),
    candidateProfile: getDefaultCandidateProfile(),
    serverVar,
    consultantJobDetail: {},
    history: {},
    lp: {}
  })
  return <Provider value={{ state: { ...state, serverVar }, dispatch, getCookie }}>{children}</Provider>
}

export const useGetCookie = () => {
  const { getCookie } = useContext(DataContext)
  return getCookie
}
export const useData = (): { state: RootStateType; dispatch: (action?: any) => void } => {
  const { state, dispatch } = useContext(DataContext)
  return {
    state,
    dispatch: (action) => {
      return dispatch(action)
    }
  }
}
