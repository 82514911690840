import { ClientBaseType } from '@legacy/@core/type/ClientType'
import { FastJsonList } from '@legacy/@core/type/Common'
import { mappingClientSelect } from 'service/mapping/consultants/clients.map'
import { ClientType } from 'type/ClientType'
import { API_URL_COMPANY_CREATE_CLIENT, API_URL_COMPANY_CREATE_CLIENT_ASSIGN, API_URL_CONSULTANT_CLIENTS } from 'utilities/APIConstant'
import Client from 'utilities/Client'
import { toPagingAPI } from 'utilities/common'
import { PAGE_ITEMS } from 'utilities/Constant'

export const getListClientsAPI = ({ rest, q, user_ids = undefined, industry_ids = undefined, page, page_size = PAGE_ITEMS }) => {
  const bd = typeof rest.bd === 'string' ? [rest.bd] : rest.bd
  const account_manager = typeof rest.account_manager === 'string' ? [rest.account_manager] : rest.account_manager

  return Client.get<FastJsonList<ClientType>>(
    toPagingAPI(
      API_URL_COMPANY_CREATE_CLIENT,
      { ...rest, ...(user_ids ? { user_ids } : null), ...(industry_ids ? { industry_ids } : null), bd, account_manager, name: q },
      page,
      page_size || PAGE_ITEMS
    ),
    {}
  )
}

export const submitCreateClientsAPI = ({ data }) => {
  return Client.post(API_URL_COMPANY_CREATE_CLIENT, { data })
}

export const submitAssignClientsAPI = ({ data }) => {
  return Client.post(API_URL_COMPANY_CREATE_CLIENT_ASSIGN, { data })
}

export const getClientsAPI = ({ searchValue }) => {
  return Client.get(API_URL_COMPANY_CREATE_CLIENT, { params: searchValue })
}
export const getConsultantClients = () => {
  return Client.get<FastJsonList<ClientBaseType>>(API_URL_CONSULTANT_CLIENTS).then((rs) => mappingClientSelect(rs.data))
}
