import css from 'styled-jsx/css'

export const VariableStyle = css.global`
  :root {
    --layout-header-height: 56px;
    --layout-footer-height: 70px;
    --layout-content-min-height: calc(100vh - var(--layout-footer-height) - var(--layout-header-height));
    --black: #000;
    --white: #fff;
    --primary: #00ace0;
    --grey-5: #d9d9d9;
    --grey-6: #bfbfbf;
    --grey-7: #8c8c8c;
    --grey-8: #595959;
    --grey-9: #262626;
    --red: #ea4335;
    --neutral: #f6f8fc;
    --highlight: #fa8c16;

    --blue: #1890ff;
  }
`
