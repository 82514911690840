import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

// import en_translation from '../../../../src/locales/en/en.json'
// import en_common from '../../../../src/locales/en/common.json'
// import en_validate from '../../../../src/locales/en/validate.json'
// import en_page_login from '../../../../src/locales/en/page_login.json'
// import en_admin from '../../../../src/locales/en/admin.json'

// import vi_translation from '../../../../src/locales/vi/vi.json'
// import vi_common from '../../../../src/locales/vi/common.json'
// import vi_validate from '../../../../src/locales/vi/validate.json'
// import vi_page_login from '../../../../src/locales/vi/page_login.json'
// import vi_admin from '../../../../src/locales/vi/admin.json'

// import jpTranslation from '../../../../src/locales/jp/jp.json'

import en_button from './en-US/button.json'
import en_label from './en-US/label.json'
import en_form from './en-US/form.json'
import en_search from './en-US/search.json'
import en_notification from './en-US/notification.json'
import en_empty from './en-US/empty.json'
import en_activity from './en-US/activity.json'
import en_email_template from './en-US/email_template.json'
import en_premium_company from './en-US/premium_company.json'
/**
 * Meta
 */
import en_meta from './en-US/meta.json'
import en_meta_employer_product from './en-US/meta_employer_product.json'
import en_status from './en-US/status.json'
import en_placeholder from './en-US/placeholder.json'
/**
 * Page
 */
import en_page_account_invite_team from './en-US/page_account_invite_team.json'
import en_page_account_change_password from './en-US/page_account_change_password.json'
import en_page_my_profile from './en-US/page_my_profile.json'
import en_page_download_app from './en-US/page_download_app.json'
import en_page_my_jobs from './en-US/page_my_jobs.json'
import en_page_home from './en-US/page_home.json'
import en_page_home_business_tag from './en-US/page_home_business_tag.json'
import en_page_employer_product from './en-US/page_employer_product.json'
import en_page_employer_headhunt_service from './en-US/page_employer_headhunt_service.json'

import en_page_employer_candidates from './en-US/page_employer_candidates.json'
import en_page_employer_jobs_create from './en-US/page_employer_jobs_create.json'
import en_page_employer_jobs_id_applicants from './en-US/page_employer_jobs_id_applicants.json'
import en_page_employer_jobs_id_detail from './en-US/page_employer_jobs_id_detail.json'
import en_page_employer_jobs_id from './en-US/page_employer_jobs_id.json'
import en_page_employer_jobs from './en-US/page_employer_jobs.json'

import en_page_employer_preview_job from './en-US/page_employer_preview_job.json'
import en_page_employer_contact from './en-US/page_employer_contact.json'
import en_page_register from './en-US/page_register.json'
import en_page_login from './en-US/page_login.json'
import en_page_forgot_password from './en-US/page_forgot_password.json'
import en_page_jobs from './en-US/page_jobs.json'
import en_page_candidates_first_use from './en-US/page_candidates_first_use.json'
import en_page_companies from './en-US/page_companies.json'
import en_page_consultants from './en-US/page_consultants.json'
import en_job_alert from './en-US/job_alert.json'
import en_skills from './en-US/skills.json'
import en_talent_pool from './en-US/talent_pool.json'
import en_experience from './en-US/experience.json'
import en_education from './en-US/education.json'
import en_certificate from './en-US/certificate.json'
import en_work_reference from './en-US/work_reference.json'
import en_job_preference from './en-US/job_preference.json'
import en_card from './en-US/card.json'
import en_offer from './en-US/offer.json'
import en_modal from './en-US/modal.json'
import en_header from './en-US/header.json'
import en_footer from './en-US/footer.json'
import en_task from './en-US/task.json'
import en_public_company from './en-US/public_company.json'
import en_page_manage_cv from './en-US/page_manage_cv.json'
import en_public_key from './en-US/public_key.json'

import vi_button from './vi-VN/button.json'
import vi_label from './vi-VN/label.json'
import vi_form from './vi-VN/form.json'
import vi_search from './vi-VN/search.json'
import vi_notification from './vi-VN/notification.json'
import vi_empty from './vi-VN/empty.json'
import vi_activity from './vi-VN/activity.json'
import vi_email_template from './vi-VN/email_template.json'
import vi_premium_company from './vi-VN/premium_company.json'
import vi_meta from './vi-VN/meta.json'
import vi_meta_employer_product from './vi-VN/meta_employer_product.json'
import vi_status from './vi-VN/status.json'
import vi_placeholder from './vi-VN/placeholder.json'
import vi_page_account_invite_team from './vi-VN/page_account_invite_team.json'
import vi_page_account_change_password from './vi-VN/page_account_change_password.json'
import vi_page_my_profile from './vi-VN/page_my_profile.json'
import vi_page_download_app from './vi-VN/page_download_app.json'
import vi_page_my_jobs from './vi-VN/page_my_jobs.json'
import vi_page_home from './vi-VN/page_home.json'
import vi_page_home_business_tag from './vi-VN/page_home_business_tag.json'
import vi_page_employer_product from './vi-VN/page_employer_product.json'
import vi_page_employer_headhunt_service from './vi-VN/page_employer_headhunt_service.json'
import vi_page_employer_candidates from './vi-VN/page_employer_candidates.json'
import vi_page_employer_jobs_create from './vi-VN/page_employer_jobs_create.json'
import vi_page_employer_jobs_id_applicants from './vi-VN/page_employer_jobs_id_applicants.json'
import vi_page_employer_jobs_id_detail from './vi-VN/page_employer_jobs_id_detail.json'
import vi_page_employer_jobs_id from './vi-VN/page_employer_jobs_id.json'
import vi_page_employer_jobs from './vi-VN/page_employer_jobs.json'
import vi_page_employer_preview_job from './vi-VN/page_employer_preview_job.json'
import vi_page_employer_contact from './vi-VN/page_employer_contact.json'
import vi_page_register from './vi-VN/page_register.json'
import vi_page_login from './vi-VN/page_login.json'
import vi_page_forgot_password from './vi-VN/page_forgot_password.json'
import vi_page_jobs from './vi-VN/page_jobs.json'
import vi_page_companies from './vi-VN/page_companies.json'
import vi_page_candidates_first_use from './vi-VN/page_candidates_first_use.json'
import vi_page_consultants from './vi-VN/page_consultants.json'
import vi_job_alert from './vi-VN/job_alert.json'
import vi_skills from './vi-VN/skills.json'
import vi_talent_pool from './vi-VN/talent_pool.json'
import vi_experience from './vi-VN/experience.json'
import vi_education from './vi-VN/education.json'
import vi_certificate from './vi-VN/certificate.json'
import vi_work_reference from './vi-VN/work_reference.json'
import vi_job_preference from './vi-VN/job_preference.json'
import vi_card from './vi-VN/card.json'
import vi_offer from './vi-VN/offer.json'
import vi_modal from './vi-VN/modal.json'
import vi_header from './vi-VN/header.json'
import vi_footer from './vi-VN/footer.json'
import vi_task from './vi-VN/task.json'
import vi_public_company from './vi-VN/public_company.json'
import vi_page_manage_cv from './vi-VN/page_manage_cv.json'
import vi_public_key from './vi-VN/public_key.json'

import jp_button from './ja-JP/button.json'
import jp_label from './ja-JP/label.json'
import jp_form from './ja-JP/form.json'
import jp_search from './ja-JP/search.json'
import jp_notification from './ja-JP/notification.json'
import jp_empty from './ja-JP/empty.json'
import jp_activity from './ja-JP/activity.json'
import jp_email_template from './ja-JP/email_template.json'
import jp_premium_company from './ja-JP/premium_company.json'
import jp_meta from './ja-JP/meta.json'
import jp_status from './ja-JP/status.json'
import jp_placeholder from './ja-JP/placeholder.json'
import jp_page_account_invite_team from './ja-JP/page_account_invite_team.json'
import jp_page_account_change_password from './ja-JP/page_account_change_password.json'
import jp_page_my_profile from './ja-JP/page_my_profile.json'
import jp_page_download_app from './ja-JP/page_download_app.json'
import jp_page_my_jobs from './ja-JP/page_my_jobs.json'
import jp_page_home from './ja-JP/page_home.json'
import jp_page_employer_product from './ja-JP/page_employer_product.json'
import jp_page_employer_headhunt_service from './ja-JP/page_employer_headhunt_service.json'
import jp_page_employer_contact from './ja-JP/page_employer_contact.json'
import jp_page_register from './ja-JP/page_register.json'
import jp_page_login from './ja-JP/page_login.json'
import jp_page_forgot_password from './ja-JP/page_forgot_password.json'
import jp_page_jobs from './ja-JP/page_jobs.json'
import jp_page_candidates_first_use from './ja-JP/page_candidates_first_use.json'
import jp_page_consultants from './ja-JP/page_consultants.json'
import jp_job_alert from './ja-JP/job_alert.json'
import jp_skills from './ja-JP/skills.json'
import jp_talent_pool from './ja-JP/talent_pool.json'
import jp_experience from './ja-JP/experience.json'
import jp_education from './ja-JP/education.json'
import jp_certificate from './ja-JP/certificate.json'
import jp_work_reference from './ja-JP/work_reference.json'
import jp_job_preference from './ja-JP/job_preference.json'
import jp_card from './ja-JP/card.json'
import jp_offer from './ja-JP/offer.json'
import jp_modal from './ja-JP/modal.json'
import jp_header from './ja-JP/header.json'
import jp_footer from './ja-JP/footer.json'
import jp_task from './ja-JP/task.json'
import jp_public_company from './ja-JP/public_company.json'
import jp_page_manage_cv from './ja-JP/page_manage_cv.json'
import jp_public_key from './ja-JP/public_key.json'

const resources = {
  en: {
    // Common translation
    button: en_button,
    label: en_label,
    form: en_form,
    search: en_search,
    notification: en_notification,
    empty: en_empty,
    activity: en_activity,
    email_template: en_email_template,
    premium_company: en_premium_company,
    meta: en_meta,
    meta_employer_product: en_meta_employer_product,
    status: en_status,
    placeholder: en_placeholder,

    // Pages
    page_account_invite_team: en_page_account_invite_team,
    page_account_change_password: en_page_account_change_password,
    page_my_profile: en_page_my_profile,
    page_download_app: en_page_download_app,
    page_my_jobs: en_page_my_jobs,
    page_home: en_page_home,
    page_home_business_tag: en_page_home_business_tag,
    page_employer_product: en_page_employer_product,
    page_employer_headhunt_service: en_page_employer_headhunt_service,

    page_employer_candidates: en_page_employer_candidates,
    page_employer_jobs_create: en_page_employer_jobs_create,
    page_employer_jobs_id_applicants: en_page_employer_jobs_id_applicants,
    page_employer_jobs_id_detail: en_page_employer_jobs_id_detail,
    page_employer_jobs_id: en_page_employer_jobs_id,
    page_employer_jobs: en_page_employer_jobs,

    page_companies: en_page_companies,

    page_employer_preview_job: en_page_employer_preview_job,
    page_employer_contact: en_page_employer_contact,
    page_register: en_page_register,
    page_login: en_page_login,
    page_forgot_password: en_page_forgot_password,
    page_jobs: en_page_jobs,
    page_candidates_first_use: en_page_candidates_first_use,
    page_consultants: en_page_consultants,
    page_manage_cv: en_page_manage_cv,

    // Components
    job_alert: en_job_alert,
    skills: en_skills,
    talent_pool: en_talent_pool,
    experience: en_experience,
    education: en_education,
    certificate: en_certificate,
    work_reference: en_work_reference,
    job_preference: en_job_preference,
    card: en_card,
    offer: en_offer,
    modal: en_modal,
    header: en_header,
    footer: en_footer,
    task: en_task,
    public_company: en_public_company,

    // Public Translation
    public_key: en_public_key

    // translation: en_translation,
    // common: en_common,
    // validate: en_validate,
    // page_login: en_page_login,
    // admin: en_admin
  },
  vi: {
    // Common translation
    button: vi_button,
    label: vi_label,
    form: vi_form,
    search: vi_search,
    notification: vi_notification,
    empty: vi_empty,
    activity: vi_activity,
    email_template: vi_email_template,
    premium_company: vi_premium_company,
    meta: vi_meta,
    meta_employer_product: vi_meta_employer_product,
    status: vi_status,
    placeholder: vi_placeholder,

    // Pages
    page_account_invite_team: vi_page_account_invite_team,
    page_account_change_password: vi_page_account_change_password,
    page_my_profile: vi_page_my_profile,
    page_download_app: vi_page_download_app,
    page_my_jobs: vi_page_my_jobs,
    page_home: vi_page_home,
    page_home_business_tag: vi_page_home_business_tag,
    page_employer_product: vi_page_employer_product,
    page_employer_headhunt_service: vi_page_employer_headhunt_service,

    page_employer_candidates: vi_page_employer_candidates,
    page_employer_jobs_create: vi_page_employer_jobs_create,
    page_employer_jobs_id_applicants: vi_page_employer_jobs_id_applicants,
    page_employer_jobs_id_detail: vi_page_employer_jobs_id_detail,
    page_employer_jobs_id: vi_page_employer_jobs_id,
    page_employer_jobs: vi_page_employer_jobs,

    page_companies: vi_page_companies,

    page_employer_preview_job: vi_page_employer_preview_job,
    page_employer_contact: vi_page_employer_contact,
    page_register: vi_page_register,
    page_login: vi_page_login,
    page_forgot_password: vi_page_forgot_password,
    page_jobs: vi_page_jobs,
    page_candidates_first_use: vi_page_candidates_first_use,
    page_consultants: vi_page_consultants,
    page_manage_cv: vi_page_manage_cv,

    // Components
    job_alert: vi_job_alert,
    skills: vi_skills,
    talent_pool: vi_talent_pool,
    experience: vi_experience,
    education: vi_education,
    certificate: vi_certificate,
    work_reference: vi_work_reference,
    job_preference: vi_job_preference,
    card: vi_card,
    offer: vi_offer,
    modal: vi_modal,
    header: vi_header,
    footer: vi_footer,
    task: vi_task,
    public_company: vi_public_company,

    // Public Translation
    public_key: vi_public_key

    // translation: vi_translation,
    // common: vi_common,
    // validate: vi_validate,
    // page_login: vi_page_login,
    // admin: vi_admin
  },
  jp: {
    // Common translation
    button: jp_button,
    label: jp_label,
    form: jp_form,
    search: jp_search,
    notification: jp_notification,
    empty: jp_empty,
    activity: jp_activity,
    email_template: jp_email_template,
    premium_company: jp_premium_company,
    meta: jp_meta,
    status: jp_status,
    placeholder: jp_placeholder,

    // Pages
    page_account_invite_team: jp_page_account_invite_team,
    page_account_change_password: jp_page_account_change_password,
    page_my_profile: jp_page_my_profile,
    page_download_app: jp_page_download_app,
    page_my_jobs: jp_page_my_jobs,
    page_home: jp_page_home,
    page_employer_product: jp_page_employer_product,
    page_employer_headhunt_service: jp_page_employer_headhunt_service,
    page_employer_contact: jp_page_employer_contact,
    page_register: jp_page_register,
    page_login: jp_page_login,
    page_forgot_password: jp_page_forgot_password,
    page_jobs: jp_page_jobs,
    page_candidates_first_use: jp_page_candidates_first_use,
    page_consultants: jp_page_consultants,
    page_manage_cv: jp_page_manage_cv,

    // Components
    job_alert: jp_job_alert,
    skills: jp_skills,
    talent_pool: jp_talent_pool,
    experience: jp_experience,
    education: jp_education,
    certificate: jp_certificate,
    work_reference: jp_work_reference,
    job_preference: jp_job_preference,
    card: jp_card,
    offer: jp_offer,
    modal: jp_modal,
    header: jp_header,
    footer: jp_footer,
    task: jp_task,
    public_company: jp_public_company,

    // Public Translation
    public_key: jp_public_key

    // translation: jpTranslation
  }
}
let currentLanguage: string
export const getCurrentLanguage = (): string => currentLanguage
export default function createI18n(lng: string): void {
  currentLanguage = lng
  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources,
      lng,
      fallbackLng: false,
      keySeparator: false, // we do not use keys in form messages.welcome

      interpolation: {
        escapeValue: false // react already safes from xss
      }
    })
}
