import { ClientType } from '@legacy/@core/utilities/APIClient'
import { createSimpleEmitter } from '@legacy/@core/utilities/Emitter'
import dayjs from 'dayjs'
import { useMemo } from 'react'
import Client from 'utilities/Client'
import { getIsServer } from 'utilities/common'
import { takeOnePromise } from 'utilities/function'
import create from 'zustand'
import pj from '../../../package.json'
const DEFAULT_TTL = 1000 * 60 * 60
const getStoreMeta = (): { [key: string]: { date: string; scope: string } } => {
  const mapOjectString = localStorage.getItem(`datasource-meta`)
  return mapOjectString && JSON.parse(mapOjectString)
}
const setStoreMeta = (objectKeyMap) => {
  localStorage.setItem(`datasource-meta`, JSON.stringify(objectKeyMap))
}
const getStorageObject = (key, ttl) => {
  const keyString = localStorage.getItem(`datasource-${key}`)
  const object = keyString && JSON.parse(keyString)
  if (object?.storeVersion !== pj.version) {
    return undefined
  }
  if (dayjs().diff(dayjs(object?.date)) > ttl) {
    return undefined
  }
  return object
}
const setStorageObject = (key, data, ttl, scope) => {
  const obj = { date: new Date(), data, storeVersion: pj.version, ttl, scope }
  localStorage.setItem(`datasource-${key}`, JSON.stringify(obj))
  const objectKeyMeta = getStoreMeta() || { storeVersion: pj.version }
  setStoreMeta({ ...objectKeyMeta, [`datasource-${key}`]: { date: obj.date, ttl: obj.ttl, scope } })
  return obj
}
const removeStorageObject = (key) => {
  localStorage.removeItem(`datasource-${key}`)
}
const { emitAll: emitClearPriveateData, regiterCallback: subscribeClearData } = createSimpleEmitter()
export const createSelectDataSource = <T, A>({
  getDataSource,
  storageKey,
  ttl = DEFAULT_TTL,
  scope = 'private'
}: {
  getDataSource: (client: ClientType, ...args: A[]) => Promise<T>
  storageKey?: string
  ttl?: number
  private?: boolean
  scope?: 'private' | 'public'
}) => {
  const store = create<{ fetched: boolean; fetching: boolean; data: T; triggerFetch: (...args: A[]) => void }>((set, get) => {
    const innitStore = !getIsServer() && storageKey && getStorageObject(storageKey, ttl)
    return {
      fetched: !!innitStore,
      fetching: false,
      data: storageKey && innitStore && innitStore.data,
      triggerFetch: takeOnePromise(function (...args: A[]) {
        if (get().fetched) {
          return Promise.resolve(get().data)
        }

        set((state) => ({ ...state, fetching: true }))
        return getDataSource(Client, ...args).then((result) => {
          if (storageKey) {
            setStorageObject(storageKey, result, ttl, scope)
          }

          set((state) => ({ ...state, fetching: false, fetched: true, data: result }))
          return result
        })
      })
    }
  })
  const invalidateCache = () => {
    store.setState((state) => ({ ...state, fetching: false, fetched: false }))
    removeStorageObject(storageKey)
  }
  subscribeClearData(() => Promise.resolve(invalidateCache()))
  const useDataSource = () => {
    const { data, fetching, triggerFetch } = store()

    return useMemo(
      () => ({
        data,
        fetching,
        triggerFetch
      }),
      [data, fetching, triggerFetch]
    )
  }
  return { store, useDataSource, invalidateCache }
}

export const clearPrivateData = () => {
  const storeMeta = getStoreMeta()
  const privateList = Object.keys(storeMeta).filter((key) => storeMeta[key].scope === 'private')
  privateList.forEach((key) => {
    localStorage.removeItem(key)
  })
  setStoreMeta(
    Object.keys(storeMeta).reduce((result, key) => (storeMeta[key].scope === 'private' ? result : { ...result, [key]: storeMeta[key] }), {})
  )
}

export const safe_clearPrivateData = takeOnePromise(() => {
  return new Promise((resolve) => {
    clearPrivateData()
    emitClearPriveateData()
    setTimeout(() => resolve(undefined), 500)
  })
})
